
import Paipan from "../../../components/Paipan.vue";
import WuxingCate from "../../../components/WuxingCate.vue";
import WuxingHl from "../../../components/WxHanLg.vue";
import solarLunar from "../../../components/datepick/solarlunar";
import { $orderNameDetail } from "../../../api/home_marriage_test"

const resultData = {
    components: {
        Paipan,WuxingCate,WuxingHl
    },
    data () {
        return {
            result_data:{},//结果页面数据
            info_data:{},//用户信息
            lunar_calendar_value:{
                cYear:"",
                yearCn_:"",
                cMonth:"",
                yearCn:"",
                cDay:"",
                monthCn:"",
                tiem_:"",
                dayCn:"",
                tiemCn_:"",
            },//农历时间
            test_info:{
                marriage_before_xg:{
                    one:'',
                    two:''
                },
                py_status:{
                    zheng:'',
                    fan:''
                },
                bm_marriage:'',
                marriage_advice:{
                    one:'',
                    two:'',
                    three:''
                },
                right_analysis:'',
                py_status_after:{
                    zheng:'',
                    fan:''
                },
                py_over:'',
                yy_method:{
                    doggo:'',
                    ml:''
                },
                m_cate:{
                    desc:''
                },


                m_cate:{},
                m_xg:{},
                m_zwh:{},

            },//测试信息
            orderinfo: {
                bDate: [2021],
                nongli: {},
                eightChara: {
                bazi: [
                    {
                    y: "辛丑",
                    },
                    {
                    m: "乙未",
                    },
                    {
                    d: "甲子",
                    },
                    {
                    h: "甲子",
                    },
                ],
                nayin: [
                    {
                    y: "壁上土",
                    },
                    {
                    m: "沙中金",
                    },
                    {
                    d: "海中金",
                    },
                    {
                    h: "海中金",
                    },
                ],
                zao: [
                    {
                    y: "辛丑",
                    },
                    {
                    m: "",
                    },
                    {
                    d: "",
                    },
                    {
                    h: "",
                    },
                ],
                },
                askTao: {
                ori_qk_score: {
                    金: 5,
                    木: 8,
                    水: 3,
                    火: 3,
                    土: 9,
                },
                asktao_result: {
                    喜用: {
                    水: 1.5,
                    金: 2.5,
                    },
                    忌用: {
                    木: 5.6,
                    火: 4.5,
                    土: 18,
                    },
                },
                qk_score_ratio: {
                    金: "6%",
                    木: "11%",
                    水: "25%",
                    火: "12%",
                    土: "46%",
                },
                ww_score_ratio: {
                    水: "38%",
                    金: "62%",
                },
                dw_score_ratio: {
                    木: "20%",
                    火: "16%",
                    土: "64%",
                },
                qk_score: {
                    金: 2.5,
                    木: 5.6,
                    水: 1.5,
                    火: 4.5,
                    土: 18,
                },
                },
            },
            enums: {
                木: "xys-mu",
                土: "xys-tu",
                火: "xys-huo",
                水: "xys-shui",
                金: "xys-jin",
            },
        }
    },
    created () {
        this.orderNameDetailDataRequest()
    },
    computed: {
		// is_weixn() {
		// 	var ua = navigator.userAgent.toLowerCase();
		// 	if (ua.match(/MicroMessenger/i) == "micromessenger") {
		// 		return true;
		// 	} else {
		// 		return false;
		// 	}
		// },
        WW() {
        return Object.keys(this.orderinfo.askTao.ww_score_ratio);
        },
        DW() {
        return Object.keys(this.orderinfo.askTao.dw_score_ratio);
        },
        WWrd() {
        return Object.keys(this.orderinfo.askTao.ww_score_ratio)
            .reduce(
            (a, b) =>
                this.orderinfo.askTao.qk_score[a] +
                this.orderinfo.askTao.qk_score[b]
            )
            .toFixed(1);
        },
        DWrd() {
        let a = 0;
        Object.keys(this.orderinfo.askTao.dw_score_ratio).forEach((key) => {
            a = a + this.orderinfo.askTao.qk_score[key];
        });
        return a.toFixed(1);
        },
    },
    methods:{
        orderNameDetailDataRequest() {//用户信息接口
            let dataRequest_data_ = {
                order_id: this.$route.params.id,
                name_id: 0,
                mnew:1
            }
            $orderNameDetail(dataRequest_data_).then( ( res ) => {//详情数据
                if ( res.status === 200) {
                    this.result_data = res.data.data
                    this.info_data = res.data.data.base
                    this.test_info = this.result_data.marriageDataNew
                    let date_arr = res.data.data.base.birth_date.substr(0,10).split("-")
                    let time_arr = res.data.data.base.birth_date.substr(11,8).split(":")
                    date_arr.push(...time_arr)
                    this.lunar_calendar_value = solarLunar.solar2lunar(//获取农历
                        date_arr[0],
                        date_arr[1],
                        date_arr[2],
                    );
                    this.lunar_calendar_value.tiem_ = time_arr[0]//时
                    this.lunar_calendar_value.tiemCn_ = (res.data.data.base.moon_time.split("#"))[1]//农历时
                    this.lunar_calendar_value.yearCn_ = (res.data.data.base.moon_time.split("#"))[0]//农历时
                }
                
            })
        }
    }

}

export default resultData