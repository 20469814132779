<template>
    <div class="increase">
        <div class="content_box_">
            <h1 class="title_">八字姻缘改运建议</h1>
            <div class="content_text_box_">
                <p style="font-weight:bold;padding-bottom:0;">脱单技巧</p>
                <p  v-html="test_info.yy_method.doggo"></p>
                <p style="font-weight:bold;padding-bottom:0;">催旺爱情建议</p>
                <p  v-html="test_info.yy_method.ml"></p>
                <p style="color:#67386F;">（注：以上红袋子包裹干货若破碎霉变需及时更换，有效期为一年，次年除夕前更换为宜）</p>
            </div>
        </div>
    </div>
</template>

<script>
import resultData from "../../../mixin/homeMarriageTest/result_content/resultData"
export default {
     mixins:[resultData],
}
</script>

<style lang="scss" scoped>
    .increase{
        .content_box_{
            width: 7.18rem;
            border: .03rem solid #DC5744;
            opacity: 0.7;
            border-radius: .30rem;
            padding-bottom: .6rem;
            margin: 0 auto;
            margin-bottom: .48rem;
            .title_{
                width: 100%;
                height: .78rem;
                line-height: .78rem;
                font-size: .36rem;
                color: #C91C18;
                text-align: center;
                font-weight: bold;
                background: #fae7e5;
                margin-top: .25rem;
            }
            .content_name_text_box_{ //您的信息
                width: 100%;
                padding-left: .69rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .27rem;
                }
                .name_{
                    margin-top: .35rem;
                }
                .span_style__1{
                    .text__{
                        letter-spacing:2em
                    }
                }
            }
            .content_text_box_{
                padding: 0 .35rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .35rem;
                }
            }
            
            
        }
    }
</style>